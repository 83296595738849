import React from "react";
import {Link, withRouter} from "react-router-dom";
import "../../css/button.css";
import styles from "./signflow.module.css";
import {InputErrMask, InputNullFullMask, InputNullMask, InputRegCodeErrMask} from "./inputmask";
import "../utils";
import Util, {checkEmail, checkPassword, checkPhoneNo, checkSmsCode, getUrlArg, hashPwd} from "../utils";
import SMSSender from "./sms-verify";
import {URL_SEND_REGCODE, URL_SIGNUP} from '../variables'
import {Path, toSubsAuth, toIndex, RedirectKey, RedirectPath} from "../path";
import {FetchUtil} from '../fetch_util'

let fetchUtil = new FetchUtil();

class SignUpForm extends React.Component {

    constructor(props) {
        super(props);
        this.showInputEmail = this.showInputEmail.bind(this);
        this.hideEmailMask = this.hideEmailMask.bind(this);
        this.showInputEmailErr = this.showInputEmailErr.bind(this);
        this.email = this.email.bind(this);
        this.showInputPhoneNo = this.showInputPhoneNo.bind(this);
        this.hidePhoneNoMask = this.hidePhoneNoMask.bind(this);
        this.showInputDigits = this.showInputDigits.bind(this);
        this.hideDigitsMask = this.hideDigitsMask.bind(this);
        this.showInputDigitsErr = this.showInputDigitsErr.bind(this);
        this.showInputPassword = this.showInputPassword.bind(this);
        this.hidePasswordMask = this.hidePasswordMask.bind(this);
        this.password = this.password.bind(this);
        this.phoneNo = this.phoneNo.bind(this);
        this.digits = this.digits.bind(this);
        this.submit = this.submit.bind(this);
        this.smsSend = this.smsSend.bind(this);
        this.setRegStatus = this.setRegStatus.bind(this);
        this.showInputPhoneNoExist = this.showInputPhoneNoExist.bind(this);
        this.state = {
            phoneNo: '',
            digits: '',
            password: '',
            email: '',
            inputPhoneNo: false,
            inputPhoneNoMsg: '请输入手机号',
            inputPhoneNoErr: false,
            inputPhoneNoErrMsg: '请输入正确的手机号/该手机号尚未注册',
            inputPhoneNoExist: false,
            inputPhoneNoExistMsg: '该手机号已注册',
            inputDigits: false,
            inputDigitsMsg: '请输入短信验证码',
            inputDigitsErr: false,
            inputDigitsErrMsg: '验证码填写错误',
            inputPassword: false,
            inputPasswordMsg: '请输入密码',
            inputPasswordErr: false,
            inputPasswordErrMsg: '密码不符合要求',
            inputEmail: false,
            inputEmailMsg: '请输入邮箱',
            inputEmailErr: false,
            inputEmailErrMsg: '',
            isReging: false
        };

        this.rc = this.getRc();
        this.fid = this.getFid();
    }

    getRc() {
        let rc = getUrlArg("r_c");
        if (Util.isNull(rc)) {
            return null;
        }
        return rc;
    }

    getFid() {
        let fid = getUrlArg("fid");
        if (Util.isNull(fid)) {
            return null;
        }
        return fid;
    }

    showInputEmail() {
        if (this.state.email.length == 0) {
            this.setState({
                inputEmail: true
            });
        }
    }

    hideEmailMask() {
        this.setState({
            inputEmail: false,
            inputEmailErr: false
        });
        this.refs.email.focus();
    }

    showInputEmailErr(errMsg) {
        this.setState({
            inputEmailErr: true,
            inputEmailErrMsg: errMsg
        });
    }

    showInputPhoneNoExist() {
        this.setState({
            inputPhoneNoExist: true
        });
    }

    showInputPhoneNo() {
        if (this.state.phoneNo.length == 0) {
            this.setState({
                inputPhoneNo: true
            });
        }
    }

    //显示请输入正确的手机号提示
    showInputPhoneNoErr(errMsg) {
        this.setState({
            inputPhoneNoErr: true,
            inputPhoneNoErrMsg: errMsg
        });
    }

    hidePhoneNoMask() {
        this.setState({
            inputPhoneNo: false,
            inputPhoneNoErr: false
        });
        this.refs.phoneNo.focus();
    }

    showInputDigits() {
        if (this.state.digits.length == 0) {
            this.setState({
                inputDigits: true
            });
        }
    }

    showInputDigitsErr(errMsg) {
        this.setState({
            inputDigitsErr: true,
            inputDigitsErrMsg: errMsg
        });
    }

    hideDigitsMask() {
        this.setState({
            inputDigits: false,
            inputDigitsErr: false
        });
        this.refs.digits.focus();
    }

    showInputPassword() {
        if (this.state.password.length == 0) {
            this.setState({
                inputPassword: true
            });
        }
    }

    showInputPasswordErr(errMsg) {
        this.setState({
            inputPasswordErr: true,
            inputPasswordErrMsg: errMsg
        });
    }

    hidePasswordMask() {
        this.setState({
            inputPassword: false,
            inputPasswordErr: false
        });
        this.refs.password.focus();
    }


    setRegStatus(isReging) {
        this.setState({
            isReging: isReging
        });
    }

    phoneNo(e) {
        let value = $.trim(e.target.value);
        this.setState({
            phoneNo: value
        });
    }

    digits(e) {
        let value = $.trim(e.target.value);
        this.setState({
            digits: value
        });
    }

    password(e) {
        let value = $.trim(e.target.value);
        this.setState({
            password: value
        });
    }

    email(e) {
        let value = $.trim(e.target.value);
        this.setState({
            email: value
        });
    }

    smsSend(callback) {
        var phoneNo = this.state.phoneNo;
        if (phoneNo.length == 0) {
            this.showInputPhoneNo();
            return;
        }
        if (!checkPhoneNo(phoneNo)) {
            this.showInputPhoneNoErr('请输入正确的手机号');
            return;
        }
        if (callback) {
            callback();
        }
        let nonceStr = Math.random().toString(36).substr(2);
        //请求后台给手机发送验证码
        fetchUtil.init()
            .setUrl(URL_SEND_REGCODE)
            .setMethod('POST')
            .setCookieCors()
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setBody({
                phoneNo: phoneNo,
                nonceStr: nonceStr,
                hash: hashPwd(nonceStr)
            })
            .setBodyType('json')
            .dofetch()
            .then((msg) => {

            })
            .catch((error) => {

            })
    }

    submit(event) {
        event.preventDefault();
        var phoneNo = this.state.phoneNo;
        var password = this.state.password;
        var email = this.state.email;
        if (email.length == 0 || phoneNo.length == 0 || password.length == 0) {
            this.showInputEmail();
            this.showInputPhoneNo();
            this.showInputPassword();
            return;
        }

        if (!checkEmail(email)) {
            this.showInputEmailErr('请输入正确的邮箱');
            return;
        }
        if (!checkPhoneNo(phoneNo)) {
            this.showInputPhoneNoErr('请输入正确的手机号');
            return;
        }

        if (!checkPassword(password)) {
            this.showInputPasswordErr('密码不符合要求');
            return;
        }
        var digits = this.state.digits;
        if (digits.length == 0) {
            this.showInputDigits();
            return;
        }
        if (!checkSmsCode(digits)) {
            this.showInputDigitsErr('验证码错误');
            return;
        }

        var digitsErr = this.showInputDigitsErr;
        var emailExists = this.showInputEmailErr;
        let showInputPhoneNoExist = this.showInputPhoneNoExist;
        var pathToIndex = toIndex;
        var pro = this.props;
        var regStatus = this.setRegStatus;
        regStatus(true);
        fetchUtil.init()
            .setUrl(URL_SIGNUP)
            .setMethod('POST')
            .setHeader({
                'Content-Type': 'application/json'
            })
            .setCookieCors()
            .setBodyType('json')
            .setBody({
                email: email,
                phoneNo: phoneNo,
                password: hashPwd(password),
                code: digits,
                rc: this.rc,
                fid: this.fid
            })
            .dofetch()
            .then((msg) => {
                var respCode = msg.respCode;
                if (respCode == 0) {
                    let respMsg = msg.respMsg
                    this.reportRegister(respMsg.userID)
                    let path_url = getUrlArg("path_uri");
                    let redirect_uri = getUrlArg("redirect_uri");
                    let oldemail = getUrlArg('email');
                    if (redirect_uri && oldemail == email) {
                        window.location.href = redirect_uri;
                    } else if (path_url) {
                        window.location.href = Path.getDisRetailList();
                    } else {
                        //注册成功
                        var data = {
                            user: email,
                            type: -2
                        };
                        pathToIndex(pro, data);
                    }
                    return;
                } else if (respCode == 1002) {
                    //验证码不正确
                    digitsErr('验证码错误');
                } else if (respCode == 1020) {
                    //邮箱存在
                    emailExists('该邮箱已被注册');
                } else if (respCode == 1024) {
                    showInputPhoneNoExist();
                }
                regStatus(false);
            })
            .catch(() => {
                regStatus(false);
            });
    }

    reportRegister(userID) {
        try {
            albatross.register(userID, 'mxd')
        } catch (e) {
            console.log(e)
        }
    }

    getPath() {
        let path_url = getUrlArg("path_url");
        if (Util.isNull(path_url)) {
            return null;
        }
        return path_url;
    }

    getParams() {
        let rc = this.rc;
        let fid = this.fid;
        let pathUrl = this.getPath();

        let arr = [];
        if (!Util.isNull(rc)) {
            arr.push('r_c=' + rc)
        }

        if (!Util.isNull(fid)) {
            arr.push('fid=' + fid)
        }

        if (!Util.isNull(pathUrl)) {
            arr.push('path_url=' + pathUrl)
        }

        let str = arr.join('&');

        return str;

    }

    render() {
        var inputEmailNullMask = this.state.inputEmail ? <InputNullMask errMsg={this.state.inputEmailMsg}/> : '';
        var inputEmailErrMask = this.state.inputEmailErr ? <InputErrMask errMsg={this.state.inputEmailErrMsg}/> : '';
        var inputPhoneNoNullMask = this.state.inputPhoneNo ? <InputNullMask errMsg={this.state.inputPhoneNoMsg}/> : '';
        var inputPhoneNoErrMask = this.state.inputPhoneNoErr ?
            <InputErrMask errMsg={this.state.inputPhoneNoErrMsg}/> : '';
        var inputPhoneNoExistMask = this.state.inputPhoneNoExist ?
            <InputErrMask errMsg={this.state.inputPhoneNoExistMsg}/> : '';
        var inputDigitsNullMask = this.state.inputDigits ? <InputNullMask errMsg={this.state.inputDigitsMsg}/> : '';
        var inputDigitsErrMask = this.state.inputDigitsErr ?
            <InputRegCodeErrMask errMsg={this.state.inputDigitsErrMsg}/> : '';
        var inputPasswordNullMask = this.state.inputPassword ?
            <InputNullFullMask errMsg={this.state.inputPasswordMsg}/> : '';
        var inputPasswordErrMask = this.state.inputPasswordErr ?
            <InputErrMask errMsg={this.state.inputPasswordErrMsg}/> : '';
        return (
            <div className={styles.container + ' ' + styles.signupContainer}>
                <div className={styles.logoWhite}>
                </div>
                <div className={styles.signContainer}>
                    <div className={styles.formTitle}>快速注册</div>
                    <div>
                        <form noValidate={true} onSubmit={this.submit} autoComplete="off">
                            <div className={styles.signInputContainer} onClick={this.hideEmailMask}>
                                <div className={styles.inputWraper}>
                                    <input type="email" name="email" ref="email" className={styles.signInput}
                                           placeholder="邮箱"
                                           onBlur={this.showInputEmail}
                                           onChange={this.email}/>
                                </div>
                                {inputEmailNullMask}
                                {inputEmailErrMask}
                            </div>
                            <div className={styles.signInputContainer} onClick={this.hidePhoneNoMask}>
                                <div className={styles.inputWraper}>
                                    <input type="tel" name="phoneNo" ref="phoneNo" className={styles.signInput}
                                           placeholder="手机号"
                                           onBlur={this.showInputPhoneNo}
                                           onChange={this.phoneNo}/>
                                </div>
                                {inputPhoneNoNullMask}
                                {inputPhoneNoErrMask}
                                {inputPhoneNoExistMask}
                            </div>
                            <div className={styles.signInputContainer} onClick={this.hidePasswordMask}>
                                <div className={styles.inputWraper}>
                                    <input type="text" name="password" ref="password" className={styles.signInput}
                                           placeholder="密码(只包含字母和数字且长度不小于8)"
                                           onBlur={this.showInputPassword}
                                           onChange={this.password}/>
                                </div>
                                {inputPasswordNullMask}
                                {inputPasswordErrMask}
                            </div>
                            <div className={styles.signInputContainer} onClick={this.hideDigitsMask}>
                                <div className={styles.inputWraper}>
                                    <input type="text" maxLength='6' ref="digits" name="digits"
                                           className={styles.signInput}
                                           placeholder="输入 6 位短信验证码" onBlur={this.showInputDigits}
                                           onChange={this.digits}/>
                                </div>
                                <div className={styles.inputBaffle}></div>
                                <div className={styles.inputErrMask}>
                                    {<SMSSender doSend={this.smsSend}/>}
                                </div>
                                {inputDigitsNullMask}
                                {inputDigitsErrMask}
                            </div>
                            <div>
                                <button
                                    className={"mxd-btn mxd-btn-primary " + styles.signBtn}
                                    type="submit"
                                    disabled={this.state.isReging}>{this.state.isReging ? '注册中...' : '注册'}
                                </button>
                            </div>
                            <div className={styles.regFooter}>
                                <span className={styles.regDeclar}>
                                注册即代表你同意
                                <a target='_blank' href={Path.getAgreement()}>《媒想到服务使用协议》</a>
                                </span>
                            </div>
                            <div className={styles.signSwitch}>
                                已有账号？<Link
                                to={Path.getSigninPath() + (this.getParams() ? '?' + this.getParams() : '')}>登录</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SignUpForm)